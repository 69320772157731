<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import {swalHelper} from "@/helpers/swal-helper";


export default {

  components: {

    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      modals: {
        carrier: {
          visible: false,

          form: {
            name: "",
            paymentUsualTaxExcluded: 0
          }
        }
      }

    };
  },

  validations: {
    modals: {
      carrier: {
        form: {
          name: {required},
          paymentUsualTaxExcluded: {required}
        }
      }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.carriers.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: "Nazwa"},
        {
          key: "paymentUsualTaxExcluded", label: "Cena bez VAT", formatter: value => {
            if (!value) {
              return "0.00 PLN"
            }

            return value.toFixed(2) + " PLN"
          }
        },
        {
          key: "paymentUsualTaxIncluded", label: "Cena z VAT", formatter: value => {
            if (!value) {
              return "0.00 PLN"
            }

            return value.toFixed(2) + " PLN"
          }
        },
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadCarriers() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/carrier/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.table.perPage,
          filter: this.table.filter
        }
      });

      this.table.items = data.carriers
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      return this.table.items;
    },

    openCarrierModal(carrier) {
      if (carrier) {
        this.modals.carrier.form = Object.assign({}, carrier)
      }

      this.modals.carrier.visible = true
    },

    hideCarrierModal() {
      this.modals.carrier.visible = false
      this.modals.carrier.form.name = ""
      this.modals.carrier.form.paymentUsualTaxExcluded = 0
    },

    createOrEditCarrier() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.modals.carrier.form.$touch();
      if (this.$v.modals.carrier.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        ...this.modals.carrier.form,
        paymentUsualTaxIncluded: this.modals.carrier.form.paymentUsualTaxExcluded * 1.23
      });

      axios.put(`/carrier`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.hideCarrierModal()
        Swal.fire("Sukces!", this.modals.carrier.form.id ? "Zaktualizowano przewoźnika" : "Dodano nowego przewoźnika", "success")
            .then(() => this.$refs.table.refresh())
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteCarrier(carrier) {
      swalHelper.yesOrNoCustomizable(() => {
        axios.delete(`/carrier/${carrier.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(() => {
          Swal.fire("Sukces!", "Przewoźnik został usunięty!", "success")
              .then(() => this.$refs.table.refresh());
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, "warning", "Czy na pewno?", "Przewoźnik zostanie usunięty", "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.carriers.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <b-button variant="success" @click="openCarrierModal(null)">Dodaj nowego przewoźnika</b-button>
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadCarriers"
                :pagination-top="true"
            >
              <template v-slot:action="{ item }">
                <div class="button-items">
                  <b-button variant="primary" class="btn-sm" @click="openCarrierModal(item)">Edytuj</b-button>
                  <b-button variant="danger" class="btn-sm" @click="deleteCarrier(item)">Usuń</b-button>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.carrier.visible"
        :title="modals.carrier.form.id ? 'Edytowanie przewoźnika' : 'Dodawanie nowego przewoźnika'"
        title-class="font-18"
        hide-footer
        @hide="hideCarrierModal"
        @esc="hideCarrierModal">
      <div class="form-group">
        <label>Nazwa</label>
        <input v-model="modals.carrier.form.name" type="text" class="form-control"
               :class="{ 'is-invalid': submitted && $v.modals.carrier.form.name.$error }"/>
        <div v-if="!$v.modals.carrier.form.name.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label>Koszt dostawy (bez VAT)</label>
        <input v-model="modals.carrier.form.paymentUsualTaxExcluded" type="text" class="form-control"
               :class="{ 'is-invalid': submitted && $v.modals.carrier.form.paymentUsualTaxExcluded.$error }"/>
        <div v-if="!$v.modals.carrier.form.paymentUsualTaxExcluded.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-right">
        <b-button @click="createOrEditCarrier" variant="success">{{
            modals.carrier.form.id ? 'Edytuj' : 'Utwórz'
          }}
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideCarrierModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>
  </Layout>
</template>